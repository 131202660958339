<template>
    <div class="privacy-policy-page">
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to me. I do not collect any personal information from users.</p>
      <p>I do not collect any cookies, nor do I run any ads.</p>
      <p>
        For any questions regarding your privacy, please contact me at: 
        <a href="mailto:arkdlefeedback@gmail.com" class="contact-link">arkdlefeedback@gmail.com</a>.
      </p>
      <h2>Disclaimer</h2>
      <p>
        This website is not affiliated with, endorsed by, or sponsored by Studio Wildcard or Ark: Survival Evolved. 
        All trademarks and game content are the property of their respective owners.
      </p>
    </div>
  </template>
  
  <style scoped>
  .privacy-policy-page {
    padding: 20px;
    background: radial-gradient(
      circle, 
      #31708f 60%, 
      #285c74 90%, 
      #1e4c5d 100%
    ); /* Subtle gradient for better visibility */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
    color: #f0f0f0; /* Light text for better contrast */
    max-width: 600px; /* Slightly larger max width for readability */
    margin: 20px auto; /* Center and add vertical spacing */
    font-family: Arial, sans-serif; /* Ensure clean, readable font */
  }
  
  h1, h2 {
    font-size: 24px; /* Heading font size */
    margin-bottom: 10px; /* Space below the heading */
  }
  
  p {
    font-size: 16px; /* Paragraph font size */
    line-height: 1.6; /* Line height for readability */
    margin-bottom: 10px; /* Space between paragraphs */
  }
  
  a.contact-link {
    color: #ffdd57; /* Highlight email link */
    text-decoration: none; /* Remove underline */
  }
  
  a.contact-link:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  </style>
  