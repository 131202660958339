const dinoList = [
    'Giganotosaurus',   'Lymantria',           'Megatherium',
    'Bulbdog',          'Hesperornis',         'Griffin',
    'Tek Stryder',      'Wyvern',              'Kaprosuchus',
    'Ammonite',         'Shinehorn',           'Diplodocus',
    'Fjordhawk',        'Ichthyornis',         'Lystrosaurus',
    'Carnotaurus',      'Gasbags',             'Rex',
    'Quetzal',          'Pteranodon',          'Yutyrannus',
    'Ichthyosaurus',    'Bloodstalker',        'Leedsichthys',
    'Deinonychus',      'Carcharodontosaurus', 'Dimetrodon',
    'Magmasaur',        'Jug Bug',             'Castoroides',
    'Velonasaur',       'Onyc',                'Plesiosaur',
    'Vulture',          'Hyaenodon',           'Araneo',
    'Pachyrhinosaurus', 'Procoptodon',         'Basilisk',
    'Argentavis',       'Dunkleosteus',        'Glowbug',
    'Megalosaurus',     'Therizinosaurus',     'Ankylosaurus',
    'Mesopithecus',     'Astrodelphis',        'Sabertooth Salmon',
    'Voidwyrm',         'Sinomacrops',         'Compy',
    'Dimorphodon',      'Otter',               'Manta',
    'Jerboa',           'Rock Drake',          'Mantis',
    'Liopleurodon',     'Carbonemys',          'Astrocetus',
    'Tusoteuthis',      'Megalania',           'Snow Owl',
    'Enforcer',         'Microraptor',         'Crystal Wyvern',
    'Reaper',           'Basilosaurus',        'Archaeopteryx',
    'Deathworm',        'Thorny Dragon',       'Ravager',
    'Seeker',           'Tropeognathus',       'Summoner',
    'Rock Elemental',   'Gacha',               'Trilobite',
    'Triceratops',      'Yeti',                'Titanomyrma',
    'Morellatops',      'Baryonyx',            'Direwolf',
    'Pachy',            'Maewing',             'Raptor',
    'Beelzebufo',       'Megalodon',           'Scout',
    'Equus',            'Daeodon',             'Sarco',
    'Ovis',             'Pulmonoscorpius',     'Ferox',
    'Angler',           'Phoenix',             'Glowtail',
    'Karkinos',         'Pegomastax',          'Noglin',
    'Terror Bird',      'Achatina',            'Amargasaurus',
    'Stegosaurus',      'Dilophosaur',         'Titanoboa',
    'Dinopithecus',     'Shadowmane',          'Direbear',
    'Iguanodon',        'Desmodus',            'Kairuku',
    'Mek',              'Chalicotherium',      'Diplocaulus',
    'Electrophorus',    'Meganeura',           'Oviraptor',
    'Titanosaur',       'Gallimimus',          'Leech',
    'Baryonyx',         'Brontosaurus',        'Mosasaurus',
    'Mammoth',          'Megachelon',          'Sabertooth',
    'Lamprey',          'Cnidaria',            'Arthropluera',
    'Kentrosaurus',     'Spinosaurus',         'Gigantopithecus',
    'Megaloceros',      'Paraceratherium',     'Parasaur',
    'Unicorn',          'Dodo',                'Phiomia',
    'Moschops',         'Giant Bee',           'Eurypterid',
    'Doedicurus',       'Featherlight',        'Pelagornis',
    'Roll Rat',         'Thylacoleo',          'Dung Beetle',
    'Nameless',         'Andrewsarchus',       'Managarmr',
    'Tapejara',         'Purlovia',            'Troodon',
    'Allosaurus',       'Coelacanth',          'Woolly Rhino',
    'Piranha'
  ];

export default dinoList;
